import React, { Component } from 'react';
import Header from '../Layout/header3';
import Footer from '../Layout/footer4';
import PageTitle from '../Layout/PageTitle';
//import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class OurClients extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                   {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Our Clients" activeMenu="Our Clients" />
          </div>
          {/* <!-- inner page banner END --> */}
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            {/* <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Our Clients</Link></h4>
                                            </div> */}
                                            <div className="et_pb_text_inner">
                                            <h2 style={{textAlign:'center'}}>OUR CLIENTS</h2>
<p style={{textAlign:'justify'}}>We are proud to be one of the leading organizations in Australia with a 5-star google review and great testimonials from our clients. Our clients are fully satisfied with our services as we genuinely value them and act in their best interest. We help them throughout their journey with us. From admissions to the course completion, we track our student’s course progress in a timely manner and motivate them to complete the course on time, reach their goals and make the desired career out of it. 
We believe that there is a pathway for everyone who wishes to migrate to Australia. We guide and assist our clients every step of the way and provide them with all the assistance they need to settle in Australia. Follow us on social media for the latest updates, our success stories, and client testimonials.
</p>
            
{/* 
<div class="elfsight-app-75c994eb-3144-40fe-af4d-1a3d754d9878" style={{backgroundColor:'#000'}}></div> */}
<div class="elfsight-app-67a4daaf-60df-48b8-af1c-df8d735f804c"></div>
<div style={{backgroundColor:'#fff',width:'100%',height:'4em',position:'absolute',marginTop:'-3em',zIndex:'9999999'}}></div>
                               
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm" method="post"
                      action="contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzPhone"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default OurClients;