import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header3 from './../Layout/header3';
import Footer4 from './../Layout/footer4';
//import GoogleMaps from "simple-react-google-maps";
import ExploreCarousel from './../Element/ExploreCarousel';
//import Index5Carousel from './../Element/Index5Carousel';
import Index5Slider from './../Element/Index5Slider';

import bg17 from './../../images/background/bg17.jpg'; 
import bg3 from './../../images/background/bg3.png';
//import bnr3 from './../../images/background/tcm-global-backroud-countries.jpg';
import bg19 from './../../images/background/tcm-global-contact-background.jpg';
//import bg19 from './../../images/background/bg19.jpg';
import bg18 from './../../images/background/bg18.jpg';
import about11 from './../../images/about/tcm-global-home-about.jpg';
import EmailContactForm from './EmailContactForm';
 
class Index5 extends Component {

    componentDidMount() {
       var i = 0;
		
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
		
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
    }
    render() {
        return (
            <> <div><h6></h6></div>
                <Header3 />
                <div><h6></h6></div>
                <div className="page-content bg-white rubik-font">
					<div className="owl-slider-banner main-slider">
						<Index5Slider />
                    </div>
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full industry-service" style={{ backgroundImage: "url(" + bg17 + ")" }}>
                            <div className="container">
                                <div className="row m-b80">
                                    <div className="col-lg-3 col-md-3">
                                        <div className="icon-bx-wraper ind-ser-bx">
                                            {/*<div className="icon-lg m-b10">*/}
                                            {/*    <Link to={"#"} className="text-primary"></Link>*/}
                                            {/*</div>*/}
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Apply for Visa</h3>
                                                <p>Find information and begin your application.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="icon-bx-wraper ind-ser-bx active">
                                           {/* <div className="icon-lg m-b10">
                                                <Link to={"#"} className="text-primary"> </Link>
                                            </div>*/}
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Company Profile</h3>
                                                <p>Highest Visa success rate in the region</p>
                                            </div>
                                            <Link to={"./company-profile"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="icon-bx-wraper ind-ser-bx">
                                            {/*<div className="icon-lg m-b10">
                                                <Link to={"#"} className="text-primary"> </Link>
                                            </div>*/}
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Study Visa</h3>
                                                <p>Enjoy a faster, more easy and more fast application.</p>
                                            </div>
                                            <Link to={"./student-visa-extension"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3">
                                        <div className="icon-bx-wraper ind-ser-bx active">
                                            {/*<div className="icon-lg m-b10">
                                                <Link to={"#"} className="text-primary"> </Link>
                                            </div>*/}
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte">Book an Appointment</h3>
                                                <p>Schedule your appointment with us.</p>
                                            </div>
                                            <Link to={"./contact"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center">
                                {/* <div className="col-lg-5 col-md-12 m-b30">  */}
                                <div className="col-lg-12 col-md-12 m-b30">
                                        <h2 className="box-title m-t0 m-b20 font-40"><span className="font-weight-400">Our team will help you through out the process </span></h2>
                                        <p className="m-b20">
                                        <strong>TCM Global </strong>is a part of The Career Makers Education Consultants Pty Ltd and is a leading name in Education and Migration. We provide tailor‐made and result‐oriented solutions to our clients seeking to study further and settle in Australia.
</p><strong>TCM Global </strong>comprises <strong>QEAC  </strong>and <strong>OMARA-certified </strong> consultants/Migration Agents in the team with an extensive experience in Australia and overseas.
<p className="m-b20">We guide our clients at every step of the way and provide them with all the assistance they need to achieve their goals whether it’s further study, visiting family/partner, or settling in Australia.
</p>
                                        <Link to={"./company-profile"} className="site-button button-md">Read More</Link>
                                    </div>
                                  {/*   <div className="col-lg-7 col-md-12">
                                        <img src={about11} className="radius-sm" alt="" />
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- About Us End --> */}
                        {/* <!-- Why Chose Us --> * /}
                        <div className="section-full bg-blue-light content-inner explore-projects" style={{ backgroundImage: "url(" + bg3 + ")" }}>
							<div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 section-head text-center">
                                            <h2 className="m-b0 font-40"><span className="font-weight-400">Our</span> Services</h2>
                                            <p className="m-b0">---------------------------------------</p>
                                        </div>
                                    </div>
                                    {/* <!-- blog post Carousel with no margin --> * /}
                                    <div className="">
										<ExploreCarousel /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Why Chose Us End --> */}
                     {/*    <div className="col-md-12 col-lg-12 text-center"><br/>
                            <h2 className="m-b0 font-40"><span className="font-weight-400">Choose Your</span> Country</h2>
                            <p className="m-b0">---------------------------------------</p>
                        </div>
                        <div className="section-full overlay-black-dark our-projects" style={{ backgroundImage: "url(" + bnr3 + ")" }}>
                            <div className="container text-white">
                                <div className="row m-lr0 d-flex align-items-stretch">
                                    <div className="col-lg-3 col-md-3 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>01</span>
                                            <div className="ind-service-info-in">
                                                <h2>Australia</h2>
                                                <p>A peaceful and relaxed atmosphere, together with the cosmopolitan charisma of cities like Sydney and Melbourne, Australia could be the ideal.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>02</span>
                                            <div className="ind-service-info-in">
                                                <h2>Canada</h2>
                                                <p>Canada comes FIRST if you seek a high quality of life and Education. It has been consistently ranked by the UN as one of the best countries in the world to live in.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>03</span>
                                            <div className="ind-service-info-in">
                                                <h2>UK</h2>
                                                <p>A significant percentage of the world’s research is done in the UK, and its institutions are consistently ranked among the world’s best. The UK attracts students from all over the world.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-3 p-lr0 d-flex ind-ser-info-bx">
                                        <div className="ind-service-info align-self-stretch">
                                            <span>04</span>
                                            <div className="ind-service-info-in">
                                                <h2>USA</h2>
                                                <p>The land of dreams, the place of opportunities, a nation of promise and the world’s most talked about nation offers high quality education; of course, it will be.</p>
                                            </div>
                                            <Link to={"#"} className="site-button btn-block d-flex justify-content-between white outline outline-2"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>*/ }

                         {/* <!-- Testimonials --> */ }
                         <div className="section-full content-inner ind-client" style={{color:'#fff',   backgroundColor:'#000',backgroundPosition:"bottom"}}>
                            {/* <div className="section-full content-inner ind-client" style={{color:'#fff',  backgroundImage: "url(" + bg18 + ")", backgroundColor:'#d8a409',backgroundPosition:"bottom"}}> */}
                            <div className="container">
                                <div className="row d-flex align-items-center">
                                
<h2 style={{color:'#fff',textAlign:'center'}}>OUR CLIENTS</h2>
<p class="row-client" style={{textAlign:'justify'}}>We are proud to be one of the leading organizations in Australia with a 5-star google review and great testimonials from our clients. Our clients are fully satisfied with our services as we genuinely value them and act in their best interest. We help them throughout their journey with us. From admissions to the course completion, we track our student’s course progress in a timely manner and motivate them to complete the course on time, reach their goals and make the desired career out of it. 
We believe that there is a pathway for everyone who wishes to migrate to Australia. We guide and assist our clients every step of the way and provide them with all the assistance they need to settle in Australia. Follow us on social media for the latest updates, our success stories, and client testimonials.
</p>
            

<div class="elfsight-app-75c994eb-3144-40fe-af4d-1a3d754d9878"></div>
                               
                                    {/* <div className="col-md-4 section-head">
                                        <h2 className="box-title m-t0 m-b10 font-40"><span className="font-weight-400">Our</span><br /> Testimonials</h2>
                                        <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry.</p>
                                        <Link to={"#"} className="site-button button-md">View Client</Link>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="">
											<Index5Carousel />
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Testimonials END --> */}
                        {/* <!-- Our Portfolio --> */}
                        <div className="section-full p-tb15 our-support content-inner-2" style={{ zIndex:'999999',margin:"-3em",backgroundImage: "url(" + bg19 + ")", backgroundRepeat: "no-repeat", backgroundSize:"100%", backgroundPosition: "bottom"}}>
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12 col-lg-12 section-head text-center">
                                        <h2 className="m-b0 font-40"><span className="font-weight-400">Contact</span> Us</h2>
                                        <p className="m-b0">We provide information, guidance, expert assistance and legal advice in your complete application process.</p>
                                    </div>
                                </div>
                                <div className="support-box-form bg-primary">
                                    <div className="row m-lr0">
                                        <div className="col-lg-6 p-lr0 d-flex">
                                        { /*<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d57803.76927259502!2d75.78311389999999!3d25.110810700000002!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1540556893926" style={{width: "100%", border: "0"}} className="d-flex align-items-stretch" allowfullscreen></iframe> 
											<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d805184.6317849847!2d144.49269473369353!3d-37.97123702210555!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d83bf81b005%3A0x886594dee107fc5!2sTCM%20Global%20Migration%20and%20Education%20Solutions!5e0!3m2!1sen!2sin!4v1667632761712!5m2!1sen!2sin" style={{width: "100%", border: "0"}} className="d-flex align-items-stretch" allowfullscreen referrerpolicy="no-referrer-when-downgrade"></iframe>*/}
                                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8543559827594!2d144.9549035153189!3d-37.816880279751786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d4c478de8e7%3A0xbb8ec7084e7444a8!2s9%2F530%20Little%20Collins%20St%2C%20Melbourne%20VIC%203000%2C%20Australia!5e0!3m2!1sen!2sin!4v1679457378411!5m2!1sen!2sin" style={{width: "100%", border: "0"}} className="d-flex align-items-stretch" allowfullscreen referrerpolicy="no-referrer-when-downgrade"></iframe>
                                            { /* <GoogleMaps
												apiKey={"AIzaSyDrAU41UTBlcEDNJgEtdlFLZeUBNBuHhzM"}
												style={{ minHeight:"100%", width: "100%" }}
												zoom={6}
												center={{ lat: 37.4224764, lng: -122.0842499 }}
												markers={{ lat: 37.4224764, lng: -122.0842499 }} //optional
											/>
											*/}
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="support-form supp-form">
                                                <div className="support-title text-white m-b30">
                                                    <h6 className="text-uppercase font-weight-500 m-b10">Support</h6>
                                                    <h2 className="font-40 font-weight-400 m-tb0">Need Help?</h2>
                                                    <p className="font-14">Contact our customer support team if you have any questions.</p>
                                                </div>
                                                <div className="dezPlaceAni">
                                                    <div className="dzFormMsg row-client"></div>
                                                    <EmailContactForm  />
                                                    {/* <form method="post" className="" action="contact.php">
                                                        <input type="hidden" value="Contact" name="dzToDo" />
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Name</label>
                                                                        <input name="dzName" type="text" required className="form-control" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Phone</label>
                                                                        <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Email Address</label>
                                                                        <input name="dzEmail" type="email" className="form-control" required placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Message...</label>
                                                                        <textarea name="dzMessage" rows="4" className="form-control" required placeholder=""></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <button name="submit" type="submit" value="Submit" className="site-button white button-md m-t10">Submit Now</button>
                                                            </div>
                                                        </div>
                                                    </form> */}

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Portfolio END --> */}
                       
                       {/*  <div className="section-full add-to-call bg-primary p-tb30">
                            <div className="container">
                                <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                    <p>&nbsp;</p>
                                    <h2 className="m-b10 m-t10 text-white">Working for your Brighter Future</h2>
                                    <div><Link to={"./why-prefer-tcm-global"} className="site-button button-md white">Learn More</Link></div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer4 />
            </>
        )
    }
}
export default Index5;