import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/header3";
import Footer from "./../Layout/footer4";
import PageTitle from "./../Layout/PageTitle";
import SectionCounter from "./../Element/SectionCounter";
///import TestimonialCarousel from "./../Element/TestimonialCarousel";
//import FormStyle from "./../Element/FormStyle";

//Images
import bnr1 from "./../../images/banner/bnr5.jpg";
import php from "./../../images/expertise/php.png";
import joomla from "./../../images/expertise/joomla.png";
import drupal from "./../../images/expertise/drupal.png";
import laravel from "./../../images/expertise/laravel.png";
import magento from "./../../images/expertise/magento.png";
import wordpress from "./../../images/expertise/wordpress.png";
import oscommerce from "./../../images/expertise/oscommerce.png";
import ecommerce from "./../../images/expertise/ecommerce.png";
import zend from "./../../images/expertise/zend-framework.png";
import ruby from "./../../images/expertise/ruby-rails.png";
import zencart from "./../../images/expertise/zencart.png";
import xcart from "./../../images/expertise/xcart.png";
import python from "./../../images/expertise/python-framework.png";
/* import bg1 from "./../../images/background/bg1.jpg";
import bgmap from "./../../images/background/bg-map.jpg";

const imageBlog = [
  { image: php, title: "Php" },
  { image: joomla, title: "Joomla" },
  { image: drupal, title: "Drupal" },
  { image: laravel, title: "Laravel" },
  { image: magento, title: "Magento 1.x" },
  { image: magento, title: "Magento 2.x" },
  { image: wordpress, title: "Wordpress" },
  { image: oscommerce, title: "Oscommerce" },
  { image: ecommerce, title: "ecommerce" },
  { image: zend, title: "Zend Framework" },
  { image: ruby, title: "Ruby Rails" },
  { image: zencart, title: "Zencart" },
  { image: xcart, title: "Xcart" },
  { image: python, title: "Python Framework" },
];
 */
class EmployerSponsoredVisa extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Expertise" activeMenu="Expertise" />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="section-head text-black ">
                      <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                      <h2 className="box-title m-tb0">
                      Employer Sponsored Visa
                        <span className="bg-primary"></span>
                      </h2>
                      This type of visa program allows overseas employees to file an application for Australian permanent residence. This visa can be applied in both non- regional and regional areas in Australia.
                      
                      <hr />
                      <h2>
                      Basic Eligibility Criteria Required for this Visa
                      </h2>
                      <p className="text-black text-justify">
                      According to this visa, you are required to be nominated by an approved type of Australian employer for a job in regional Australia. After that, you are required to be less than 45 years of age. Along with that, you are required to meet the skills, qualifications and English language requirements.
                      </p>
                      <h2>How does this Visa Decide your Stay?</h2>
                      <p className="text-black text-justify">
                        This is a particular type of permanent visa that lets you stay in a country like Australia indefinitely. Then you are permitted to become a permanent resident on the day the visa is allotted to you.
                      </p>
                        <p className="text-black text-justify">
It offers you an advantage to include your family members in your application when you apply. Then it gives you an opportunity to add your parents and relatives to your Australian visa application after you apply.
                        </p>
                        
                        <h2>If you want to choose this visa as an option then, your parents and relatives are:</h2>

                      <ul>
                        <li>
                        Your basic partner

                        </li>
                        <li>
                        Your dependent child or stepchild

                        </li>
                        <li>
                        Your life partner’s dependent child or stepchild
                        </li> 
                        <li>
                        The dependent child or stepchild of your or your partner’s dependent child or stepchild
                        </li>
                      </ul>
                      
                      <p className="text-black text-justify">
                      You have the chance to include someone who holds a subclass 457 visa or subclass 482 visas if we offer them this visa on the basis of their family.

                      

                      </p>
                      <p className="text-black text-justify">
                      The near and dear ones who apply for the visa with you must meet our health and character requirements. The best Australian immigration consultant offers you one of the best guidance about what you can do with this visa. The loved ones who do not apply for the visa with you might also have to meet the basic requirements.
                      <p className="text-black text-justify">
Huge numbers of visa options are available for employer sponsorship. The list below includes the main requirements for employer-sponsored visas. Employers will need to be assessed to ensure the company meets all the requirements for sponsorship. If you are an employer then please consult the Best Migration Consultant in Australia.
</p><p className="text-black text-justify">
Apart from the visa options below the applicant may qualify for several other visas which include work rights. You are required to note, skilled occupation lists changed recently, several occupation lists now exist for each visa subclass including extra types of requirements for many occupations. To ensure you obtain precise information for your specific situation you should arrange a migration assessment with one of our Registered Immigration Specialists to confirm the best visa option for your type of situation.
</p>                     </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default EmployerSponsoredVisa;
