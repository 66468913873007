import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
//import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyInUK extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                  {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Study in UK" activeMenu="Study in UK" />
          </div>
          {/* <!-- inner page banner END --> */}
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>United Kingdom</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">
                                                <p className="text-justify">The United Kingdom is the second most popular education destination among international students after the US. The UK has traditionally been a center of higher education with many of its universities being centuries old. With its glorious history of being a pioneer in higher education and stress on research and innovation, the UK has set a benchmark for other countries to follow.</p>
                                                <p>Here are a few major factors that make the US the ideal destination for higher education:</p>
                                                <h4>1. Excellent system of education</h4>
                                                <p className="text-justify">According to QS World Rankings 2019, four out of the top 10 universities are located in the UK. The UK universities Oxford and Cambridge have for centuries been two of the most reputed universities in the world. UK universities stress on practical knowledge rather than textbook learning. This focus on utility-based learning equips students with critical thinking and analytical skills.</p>
                                                <h4>2. High-quality education</h4>
                                                <p className="text-justify">The UK has an independent body called the Quality Assurance Agency (QAA) to assess the education standards of the colleges and universities in the country. This assures quality in learning, teaching, and research in every course offered by these institutions.</p>
                                                <h4>3. Shorter duration</h4>
                                                <p className="text-justify">The duration of courses in the UK is comparatively shorter. While an undergraduate course takes three years to complete, a graduate program can be finished in just one year. It saves your time and of course money in terms of cost of living and educational expenses.</p>
                                                <h4>4. Option to do a part-time job</h4>
                                                <p className="text-justify">Students can work part-time for up to 20 hours a week during semesters and full-time during semester breaks. It will help you to meet your expenses and earn significant work experience.</p>
                                                <h4>UK Student visa</h4>
                                                <p className="text-justify">The students who wish to come to the UK to pursue higher education must apply for Tier 4 (General) Student Visa. In order to be eligible for this visa, the student must be 16 years old. You can apply for a visa three months before the starting date of your course. Though the visa processing usually takes only 15 days, it is ideal to expect a delay during peak times. While applying for the visa, the student must submit all the required information and documents. You must also prove that you are financially capable of supporting your studies in the UK. </p>
                                                <p className="text-justify">If you wish to study in UK, and you need any assistance, our expert UK study abroad consultants at TCM Global Migration and Education Solutions can help you throughout the process.</p>  </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyInUK;