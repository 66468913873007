import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/header3";
import Footer from "./../Layout/footer4";
import PageTitle from "./../Layout/PageTitle";
import SectionCounter from "./../Element/SectionCounter";
//import TestimonialCarousel from "./../Element/TestimonialCarousel";
//import FormStyle from "./../Element/FormStyle";

//Images
import bnr1 from "./../../images/banner/bnr5.jpg";
import php from "./../../images/expertise/php.png";
import joomla from "./../../images/expertise/joomla.png";
import drupal from "./../../images/expertise/drupal.png";
import laravel from "./../../images/expertise/laravel.png";
import magento from "./../../images/expertise/magento.png";
import wordpress from "./../../images/expertise/wordpress.png";
import oscommerce from "./../../images/expertise/oscommerce.png";
import ecommerce from "./../../images/expertise/ecommerce.png";
import zend from "./../../images/expertise/zend-framework.png";
import ruby from "./../../images/expertise/ruby-rails.png";
import zencart from "./../../images/expertise/zencart.png";
import xcart from "./../../images/expertise/xcart.png";
import python from "./../../images/expertise/python-framework.png";
/* import bg1 from "./../../images/background/bg1.jpg";
import bgmap from "./../../images/background/bg-map.jpg";

const imageBlog = [
  { image: php, title: "Php" },
  { image: joomla, title: "Joomla" },
  { image: drupal, title: "Drupal" },
  { image: laravel, title: "Laravel" },
  { image: magento, title: "Magento 1.x" },
  { image: magento, title: "Magento 2.x" },
  { image: wordpress, title: "Wordpress" },
  { image: oscommerce, title: "Oscommerce" },
  { image: ecommerce, title: "ecommerce" },
  { image: zend, title: "Zend Framework" },
  { image: ruby, title: "Ruby Rails" },
  { image: zencart, title: "Zencart" },
  { image: xcart, title: "Xcart" },
  { image: python, title: "Python Framework" },
]; */

class PartnerFamilyVisa extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Expertise" activeMenu="Expertise" />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="section-head text-black ">
                      <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                      <h2 className="box-title m-tb0">
                      Family/Partner Visa
                        <span className="bg-primary"></span>
                      </h2> <p className="text-black text-justify">
                      The Australian residents and permanent residents can sponsor partners for migration to Australia. To sponsor your life partner, you are required to be married, in a relationship, have a registered relationship or be engaged by nature. If you are married to or living in a de facto relationship with a citizen of Australia, the permanently settled or eligible New Zealand citizen, your partner may sponsor you for migration to Australia. If you want full awareness about this visa then you can consult the best immigration consultant in Melbourne.   
                      </p><hr />
                      <h3>
                      There are two main partner visa options available:
                      </h3>
                      <h2 className="box-title m-tb0">
                      Partner Visa
                        <span className="bg-primary"></span>
                      </h2>

                      <p className="text-black text-justify">
                      This type of visa is for those who are married or in a relationship with an Australian citizen, permanent resident or eligible NZ citizen. This leads to permanent residence, usually over a duration of 2 years.  </p>
                      <h2>Fiance visa</h2>
                      <p className="text-black text-justify">
                      The fiance visa is a 9-month temporary type of visa which allows applicants to travel to a country like Australia to marry their Australian partner. Once married in this country, you would look at applying for a partner visa to obtain your permanent residence in Australia.
                      </p>
                      <h2>Parents visa</h2>
                        <p className="text-black text-justify">
                        Parents with at least half of their children staying permanently in Australia may be legal for a parent visa which provides them permanent residency in a country like Australia. The near and dear ones over a certain age may be eligible to apply for an onshore ‘aged parents’ visa which permits them to remain in a country like Australia on a bridging visa during processing. This category of visa enables parents of children who are Australian citizens or permanent residents to be sponsored for temporary or permanent residence in a country like Australia. Currently, there are two different types of visas; the faster, more expensive type of Parent visa, and the slower, inexpensive standard Parent visa.
                        </p>
                        
                        <h2>Duration and Conditions</h2>
                        <p className="text-black text-justify">
                        The Contributory Parent visas are valid for 2 years, during which time the applicant must file an application to a permanent Contributory Parent visa application. All the permanent parent visas have the right to remain in the country like Australia indefinitely, but a travel validity of 5 years from permission.
                        </p>
                        <h2>Criteria for the getting Australian Visa</h2>
                          <ol>
                        <li>
                        The family members must be sponsored by a child who is an Australian citizen or permanent resident of Australia. The sponsor must be “settled” – generally, this means lawfully resident in this country for at least 2 years, though a shorter time may be acceptable for Australian Citizens. If your near and dear ones are confused about this visa then you can take help from Education visa consultant Melbourne

                        </li>
                        <li>
                        The visa aiming applicant must pass out the “balance of family test”. That is, the number of children who are legally and permanently resident in a country like Australia must be:
                            <ul type="disc" >
                            <li>
                            Greater than, or equal to, the total number of children who are resident overseas                            </li> 
                            <li>
                            Greater than the greatest number of children who are a permanent resident in any single overseas country.
                            </li>
                            </ul>
                        </li>
                        </ol>
                       
                      
                    
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default PartnerFamilyVisa;
