import React, {Component} from 'react';
import Slider from "react-slick";
import {Link} from 'react-router-dom';

//Images
import visa1 from './../../images/our-services/industry/tcm-global-migration-visa-service.jpg';
import visa2 from './../../images/our-services/industry/tcm-global-student-visa-service.jpg';
import visa3 from './../../images/our-services/industry/tcm-global-business-visa-service.jpg';
import visa4 from './../../images/our-services/industry/tcm-global-family-visa-service.jpg';
import visa5 from './../../images/our-services/industry/tcm-global-working-holiday-visa-service.jpg';
import visa6 from './../../images/our-services/industry/tcm-global-visa-refusal-service.jpg';

const contentBlog =[
	{images: visa1, title: 'Immigration Visa', desc:'This Program is for points-tested skilled workers who are not sponsored by an employer and who have skills in particular occupations required in Australia.',},
	{images: visa2, title: 'Student Visa', desc:'If you are planning to study in Australia, you will need a student visa to study in Australia. There are different types of student visas depending on the level of study chosen.',},
	{images: visa3, title: 'Business Visa', desc:'If you are interested in investing or opening a business in Australia, you may be eligible for Business or Investor visas.',},
	{images: visa4, title: 'Family Visa',desc:'Australian citizens or permanent residents and NZ citizens can bring their family members, including parents, partners, children and other relatives to Australia.', },
	{images: visa5, title: 'Working Holiday Visa', desc:'Planning to stay and work while you’re visiting Australia? If you’re aged between 18 and 30 and hold a passport for a country or region participating in Australia’s then Working Holiday Visa can help you.',},
	{images: visa6, title: 'Refusal Visa', desc:'Is your Visa refused or cancelled? Australian Laws allow you to appeal in AAT, which can help you to get a right visa. Send your Enquiry today, Our team will reach you back to help you regarding this.',},
]

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next flaticon-right-arrow"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
    const { onClick } = props;
    return (
		<div className="owl-nav">
			<div className=" owl-prev flaticon-left-arrow" onClick={onClick} style={{zIndex:1}}/>
		</div>
    );
} 

class ExploreCarousel extends Component{
	render(){
		var settings = {		
			arrows: true,
            slidesToShow: 3,		
            infinite: true,
			dots: false,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 1024,
				  settings: {
					slidesToShow: 3,
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 2,
				  }
				},
				{
				  breakpoint: 480,
				  settings: {
					slidesToShow:1,
				  }
				}
			]
        };
		return(
			<>
				<Slider className="explore-carousel mfp-gallery owl-loaded owl-theme owl-carousel gallery owl-btn-center-lr owl-btn-2 primary " {...settings}>
					{contentBlog.map((data,index)=>(
						<div className="item p-3" key={index}>
							<div className="dlab-box">
								<div className="dlab-media dlab-img-effect"> <Link to={"#"}><img src={data.images} alt="" /></Link> </div>
								<div className="dlab-info bg-white">
									<h5 className="dlab-title m-t0"><Link to={"#"}>{data.title}</Link></h5>
									<p className="m-b0">{data.desc}</p>
								</div>
								<Link to={"#"} className="site-button btn-block d-flex justify-content-between white"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
							</div>
						</div>
					))}					
				</Slider>
			</>
		)
	}
	
}

export default ExploreCarousel;