import React, { Component } from 'react';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
//import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class StudyInNewZealand extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                   {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Study in New Zealand" activeMenu="Study in New Zealand" />
          </div>
          {/* <!-- inner page banner END --> */}
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>New Zealand</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">
                                                <p className="text-justify">New Zealand, the country known for beautiful landscapes, is one of the most preferred education destinations for international students from around the world. Around 1.25 lakh students visit New Zealand every year to pursue higher education. The top-class education system, internationally recognized degrees, opportunities for research and high standards of living are the major factors that make New Zealand an attractive place for abroad education. New Zealand is a safe and peaceful country with a tolerant society and an effective support system for international students. These are some of the other factors that make New Zealand the best destination for higher education. We have some experienced New Zealand study abroad consultants who can give assistance for your application processing.</p>
                                                <h3>Why choose New Zealand?</h3>
                                                <h4>World class education system</h4>
                                                <p className="text-justify">The New Zealand system of higher education encourages students to think critically and teaches them how to deal with different situations in an organized manner. The education system trains students to work both independently and in a team and adopt creative approaches for problem-solving.</p>
                                                <h4>Internationally recognized degrees</h4>
                                                <p className="text-justify">The universities in New Zealand are ranked high in QS World Rankings and Times Higher Education Rankings. The degrees awarded by these universities are recognized worldwide. The universities in New Zealand are required to register with the New Zealand Qualifications Authority (NZQA) and comply with certain quality standards to be eligible to enroll international students.</p>
                                                <h4>Research opportunities</h4>
                                                <p className="text-justify">The New Zealand system of higher education offers vast opportunities for students to research and innovate. They have experienced teachers to guide them, state-of-the-art laboratories and the latest technology equipment. As a result of this research-centric approach to education, New Zealand has become a hub of research and development in healthcare, engineering, computer science and agriculture. The country encourages international students to take up research as they need only to pay the same tuition fee as the local students to pursue a Ph.D.</p>
                                                <h4>Peaceful environment</h4>
                                                <p className="text-justify">New Zealand is a safe and peaceful country ranked second in the Global Peace Index 2018. It means international students can study in a peaceful environment and tolerant atmosphere without worrying about their safety.</p>
                                                <h4>High standards of living</h4>
                                                <p className="text-justify">New Zealand offers high standards of living in a multi-cultural environment. Besides, international students get a chance to enjoy the natural beauty of New Zealand, a country known for picturesque landscape, sandy beaches and green mountains.</p>
                                                <h4>Opportunities for part-time work</h4>
                                                <p className="text-justify">International students are allowed to work up to 20 hours per week during the semesters and work full-time during semester breaks. The students who pursue masters or doctoral degree are allowed to work full-time.</p>
                                                <h3>Institutions of higher education</h3>
                                                <h4>Universities</h4>
                                                <p className="text-justify">New Zealand has eight universities that offer undergraduate, masters and doctoral degrees in a range of subjects including commerce, science, and humanities. Some universities offer degrees in specialized fields such as medicine, agriculture, and engineering. Many of the universities in New Zealand have campuses in different cities and some offer overseas programs.</p>
                                                <h4>Institutes of Technology and Polytechnics (ITPs)</h4>
                                                <p className="text-justify">The Institutes of Technology and Polytechnics (ITPs) in New Zealand offer professional and vocational education and training. Many ITPs offer postgraduate courses including up to Doctoral (Ph.D.) level. The courses offered by Institutes of Technology and Polytechnics (ITPs) are more vocationally oriented, emphasizing practical experience and application to work situations. The degrees offered by these institutions are equal to university degrees.</p>
                                                <h4>Private Training Institutions</h4>
                                                <p className="text-justify">New Zealand has 600 registered Private Training Establishments (PTEs). These institutions offer courses on English language learning, travel, and tourism, design and ICT.</p>
                                                <h3>New Zealand Student visa</h3>
                                                <p className="text-justify">The students who want to pursue studies in New Zealand need to obtain a student visa. In order to be eligible for a student visa, a student must be enrolled for a full-time recognized educational course and show proof of his/her financial capacity to meet the education and living expenses. The student or his/her parents must have a minimum bank balance of NZ$10,000 for a year.</p>
                                                <p>If you wish to study in New Zealand, for any assistance visit or call TCM Global Migration and Education Solutions.</p></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default StudyInNewZealand;