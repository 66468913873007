import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
import SectionCounter from './../Element/SectionCounter';
//import TestimonialCarousel from './../Element/TestimonialCarousel';
//import FormStyle from './../Element/FormStyle';

//Images
import  bnr1 from './../../images/banner/bnr5.jpg';
import  php from './../../images/expertise/php.png';
import  joomla from './../../images/expertise/joomla.png';
import  drupal from './../../images/expertise/drupal.png';
import  laravel from './../../images/expertise/laravel.png';
import  magento from './../../images/expertise/magento.png';
import  wordpress from './../../images/expertise/wordpress.png';
import  oscommerce from './../../images/expertise/oscommerce.png';
import  ecommerce from './../../images/expertise/ecommerce.png';
import  zend from './../../images/expertise/zend-framework.png';
import  ruby from './../../images/expertise/ruby-rails.png';
import  zencart from './../../images/expertise/zencart.png';
import  xcart from './../../images/expertise/xcart.png';
import  python from './../../images/expertise/python-framework.png';
/* import bg1 from './../../images/background/bg1.jpg';
import bgmap from './../../images/background/bg-map.jpg';

const imageBlog = [
	{image: php, title: 'Php', },
	{image: joomla, title: 'Joomla', },
	{image: drupal, title: 'Drupal', },
	{image: laravel, title: 'Laravel', },
	{image: magento, title: 'Magento 1.x', },
	{image: magento, title: 'Magento 2.x', },
	{image: wordpress, title: 'Wordpress', },
	{image: oscommerce, title: 'Oscommerce', },
	{image: ecommerce, title: 'ecommerce', },
	{image: zend, title: 'Zend Framework', },
	{image: ruby, title: 'Ruby Rails', },
	{image: zencart, title: 'Zencart', },
	{image: xcart, title: 'Xcart', },
	{image: python, title: 'Python Framework', },
]; */

class SkilledMigrationVisa extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Expertise' activeMenu='Expertise' />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    <div className="content-block">
                        {/* <!-- About Us --> */}
                        <div className="section-full content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8 col-md-7">
                                        <div className="section-head text-black ">
                                            <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                                            <h2 className="box-title m-tb0">Skilled Migration Visa
                                                <span className="bg-primary"></span></h2>
                                            <p className="text-black text-justify">
                                            It is crystal clear that the students who are studying on an international level have a wide number of advantages in applying for skilled migration. There are different points available for studies in Australia, particularly when writing a done mark to higher qualifications or studying in a regional type of area. It is required to have a proper Australian qualification that makes it easier to pass a skills assessment in a skilled job sector.
</p> 
<p className="text-black text-justify">
The main purpose of the Skilled Migration Program is to grab the attention of highly employable people for migration to a country like Australia. We can easily consider that it is the most common form of migration to this country. The Skilled Migration visa is primarily issued to individuals with special types of skills and willing to settle in Australia. This type of visa applies to a wide variety of individuals who can get sponsorship from an Australian employer. If you want detailed information about this visa then you are required to consult the best immigration services Melbourne.
</p>
<p className="text-black text-justify">
Under the provisions of the Australian visa program skilled professionals, workers, or migrants might use their professional skills, qualifications, and experience to move and settle in Australia. One must go through a Skill Select program involving two types of steps, which says, submission with the help of Interest, and actual visa application process. Your documentation for skilled visas for Australia is subject to test score obtained in this Skill Select program test. Except for your desired skill set and experience, relevant knowledge of the English language is a very important parameter in determining your eligibility criteria for obtaining an Australian skilled visa.
</p>
Here are some of the important requirements for a Migration Visa of Australia is as follows:
<ul>

<li>
<b>Age limit</b>  – The age of the candidate should be under 45</li>
<li><b>English language</b>  – It is required to have sufficient ability in the English language to work in a country like Australia.
</li>
<li><b>Nominated schemed occupation</b>  – when you apply for a skilled occupation, which fits your skills and level of qualifications. This level of occupation must be found on the Medium and Long-term Strategic Skills List
</li>
<li><b>Required Skill assessment</b>  – before you apply for the desired section, you are required to have your skills assessed by the Australian assessing authority designated to assess your nominated occupation.
</li>
<li><b>Full Health assessment</b>  – you should be of reasonably good in the health sector and all the desiring applicants must have their health assessed by a specific doctor and undergo a medical examination
</li>
<li><b>Full Character assessment</b> – you should have a good character background and this too will be assessed.
    </li></ul>
         
                                        </div>
                                         

                                    </div>
                                    <div className="col-lg-4 col-md-5 sticky-top">
                                        <form className="inquiry-form inner dzForm" action="script/contact.php">
                                            <div className="dzFormMsg"></div>
                                            <h3 className="box-title m-t0 m-b10">Need Help? <span className="bg-primary"></span></h3>
                                            <p></p>
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-user text-primary"></i></span>
                                                            <input name="dzName" type="text" required className="form-control" placeholder="First Name" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-mobile text-primary"></i></span>
                                                            <input name="dzOther[Phone]" type="text" required className="form-control" placeholder="Phone" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-email text-primary"></i></span>
                                                            <input name="dzEmail" type="email" className="form-control" required placeholder="Your Email Id" />
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="ti-agenda text-primary"></i></span>
                                                            <textarea name="dzMessage" rows="4" className="form-control" required placeholder="Type your message here...."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <div className="form-group">
                                                        <div className="input-group">
                                                            <div className="g-recaptcha" data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN" data-callback="verifyRecaptchaCallback" data-expired-callback="expiredRecaptchaCallback"></div>
                                                            <input className="form-control d-none" data-recaptcha="true" required data-error="Please complete the Captcha" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">
                                                    <button name="submit" type="submit" value="Submit" className="site-button button-lg"> <span>Send Message!</span> </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Our Services --> */}
                        {/* <!-- Why Chose Us --> */}
							<SectionCounter />
                        {/* <!-- Why Chose Us End --> */}
                        {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>

                <Footer />
            </>
        )
    }
}
export default SkilledMigrationVisa;