import React,{Component} from 'react';
import {Link} from 'react-router-dom';

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					{/* <li className="active"><Link to={'./'}><span className="ti-home"></span> </Link>
					</li> */}
					<li><Link to={'#'}>About Us <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'company-profile'} className="dez-page">Company Profile</Link></li>
							<li><Link to={'team'} className="dez-page">Our Team</Link></li>
							<li><Link to={'why-prefer-tcm-global'} className="dez-page">Why Prefer TCM Global</Link></li>
							<li><Link to={'overseas-office'} className="dez-page">Overseas Office & Team</Link></li>
						{/*	<li><Link to={'#'} className="dez-page">Core Values</Link></li>
							<li><Link to={'#'} className="dez-page">Code of Conduct</Link></li>*/}
						</ul>
					</li>

					<li><Link to={'#'}>Visa Services <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'skilled-migration-visa'} className="dez-page">Skilled Migration Visa</Link></li>
							<li><Link to={'working-holiday-visa'} className="dez-page">Working Holiday Visas</Link></li>
							<li><Link to={'employer-sponsored-visa'} className="dez-page">Employer Sponsored Visa</Link></li>
						{/*	<li><Link to={'#'} className="dez-page">Working & Skilled Visas<i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'#'} className="dez-page">Skilled Independent Visa (Subclass 189)</Link></li>
									<li><Link to={'#'} className="dez-page">Skilled Work Regional Visa (Subclass 491) Post</Link></li>
									<li><Link to={'#'} className="dez-page">Skilled Nominated Visa (Subclass 190)</Link></li>
									<li><Link to={'#'} className="dez-page">Skilled Regional Visa (Subclass 887)</Link></li>
								</ul>
							</li>
							<li><Link to={'#'} className="dez-page">Other Visas & Services<i className="fa fa-angle-right"></i></Link>
								<ul className="sub-menu">
									<li><Link to={'#'} className="dez-page">Australian Visa Services</Link></li>
									<li><Link to={'#'} className="dez-page">AAT Appeals Post</Link></li>
									<li><Link to={'#'} className="dez-page">Citizenship</Link></li>
									<li><Link to={'#'} className="dez-page">Partner Visa</Link></li>
									<li><Link to={'#'} className="dez-page">Skill Assessment</Link></li>
									<li><Link to={'#'} className="dez-page">Visitor Visa</Link></li>
								</ul>
							</li> */}
							<li><Link to={'partner-family-visa'} className="dez-page">Partner/Family Visa</Link></li>
							<li><Link to={'business-immigration'} className="dez-page">Business Immigration</Link></li>
							<li><Link to={'student-visa-extension'} className="dez-page">Student Visa Extension</Link></li>
							<li><Link to={'visitor-to-student-visa'} className="dez-page">Visitor to Student Visa</Link></li>
						</ul>
					</li>
					<li><Link to={'#'}>Student Services<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
								<li><Link to={'admission-process'} className="dez-page">Admission Process</Link></li>
								<li><Link to={'course-change'} className="dez-page">Course Change</Link></li>
								{/*		<li><Link to={'#'} className="dez-page">Professional Year</Link></li>
								<li><Link to={'#'} className="dez-page">Temporary Graduate Visa (Subclass 485)</Link></li> */}
						</ul>
					</li>
					<li><Link to={'#'}>Study Visa <i className="fa fa-chevron-down"></i></Link>
					<ul className="sub-menu ">
							<li><Link to={'study-in-australia'} className="dez-page">Australia Student Visa</Link></li>
							<li><Link to={'study-in-canada'} className="dez-page">Canada Student Visa</Link></li>
							<li><Link to={'study-in-newzealand'} className="dez-page">New Zealand Student Visa</Link></li>
							<li><Link to={'study-in-uk'} className="dez-page">UK Student Visa</Link></li>
							<li><Link to={'study-in-usa'} className="dez-page">USA Student Visa</Link></li>

							 
					</ul>
				</li>
					  <li  ><Link to={'our-clients'}>Our Clients </Link> 
					</li> 
					<li><Link to={'#'}>Contact <i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'contact'} className="dez-page">Contact Us</Link></li>
							{/*		<li><Link to={'#'} className="dez-page">Our Location</Link></li>
							<li><Link to={'#'} className="dez-page">Enquiry</Link></li>*/}
						</ul>
					</li>
				</ul>
		</>
		)
	}
}
export default HeaderMenu;