import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../../Layout/header3';
import Footer from './../../Layout/footer4';
import PageTitle from './../../Layout/PageTitle';
import SectionCounter from './../../Element/SectionCounter';
//import TestimonialCarousel from './../../Element/TestimonialCarousel';
//import FormStyle from './../../Element/FormStyle';

//Images
import  about9 from './../../../images/about/pic9.jpg';
import bg2 from './../../../images/background/bg-map.jpg';
import services1 from './../../../images/our-services/pic1.jpg';
import services2 from './../../../images/our-services/pic2.jpg';
//import bg1 from './../../../images/background/bg1.jpg';
import bg1 from './../../../images/background/tcm-global-contact-background.jpg';

import bnr from './../../../images/banner/bnr2.jpg';

class Aboutus1 extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Company Profile' activeMenu='Company Profile' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">About TCM Global - Migration & Education Solutions</h2>
                               {/*     <h2 className="box-title m-tb0">Why you should choose Us?<span className="bg-primary"></span></h2>
                                    <p>TCM Global - Migration & Education Solutions is the best immigration consulting company who delivers seamless end-to-end guidance to aspiring clients in achieving their life goals without facing any hurdles.
                                        By making good use of their affluent experience in the immigration process, our consultants cater a clear picture to the clients regarding the entire immigration process and guide them in clearing the related complexities in a hassle free way.

                                        We follow a unique and standardised approach in providing immigration services like Permanent Residence, Temporary Residence Visa that help our clients to get landed in the perfect destination of opportunities according to their profile.

                                        Our immigration consulting service wings got widespread to key opportunity regions like Canada, Australia, New Zealand, UK & USA and we have quite been successful in immigrating clients of different field of interest since our inception due to the unique approach practised by us.</p>
                               */} </div>
                            </div>
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="abuot-box left row m-lr0 ">

                                            <div className="col-lg-12 text-black text-justify">
                                                <h3 className="text-gray-dark">About Us</h3>
                                                 <p className="m-b0"> <strong>TCM Global </strong>(Registered as The Career Makers overseas Education Consultants) <strong>License No. 475/MC-1/MA/F.No/254</strong> was incepted in 2010 in the city of Jalandhar, Punjab, India and we are proud to be present in the Education Market as one of the most Trusted and Genuine companies at the National & International Levels promoting education abroad and locally.</p>
                                                <p className="m-b0">
                                                <strong>TCM Global</strong> started its virtual operations in Australia in a home-based office at 36 Newmarket Parade, Mickleham, VIC, 3064 in October 2020 due to pandemic and now we are fully Operational in the heart of Melbourne City at Suite 308, 530 Little Collins Street, Melbourne, Vic, 3000, since August 2022. </p>
<p className="m-b0"><strong>TCM Global</strong> is a part of The Career Makers Education Consultants Pty Ltd and is a leading name in Education and Migration. We provide tailor‐made and result‐oriented solutions to our clients who are seeking to study in Australia both Onshore and Offshore. </p>
<p className="m-b0"><strong>TCM Global</strong> comprises QEAC and OMARA certified consultants/Migration Agents in the team with an extensive experience of 5 years plus in Australia. <br/><br/></p>
 
<p className="m-b0"><h3 className="m-t0 m-b10 font-weight-400 font-25"> Memberships

</h3>
								 
                                    <ul>We are proud members of the following:

                                        <li>MIA: Migration Institute of Australia</li>
                                        <li>MA: Migration Alliance</li>
                                        <li>QEAC: R049</li>
                                    </ul>
                                </p>

  {/* <strong>TCM Global Migration and Education Solutions</strong> (Registered as The Career Makers overseas Education Consultants) <strong>License No. 475/MC-1/MA/F.No/254 </strong> was incepted in 2010 in the city of Jalandhar, Punjab, India. We are proud to be present in the Education and Migration industry as one of the most Trusted and Genuine companies at the national & international levels promoting education and migration abroad. </p>
                                                <p  className="m-b0"><strong>TCM Global Migration and Education Solutions</strong> (Registered as The Career Makers Education Consultants Pty Ltd) <strong>ABN No. 866 449 821 14 </strong> started its Global operations in October 2020 during the COVID-19 pandemic and now going fully Operational in the heart of Melbourne CBD, Victoria, Australia. We provide tailor‐made and result‐oriented solutions to our clients who are seeking to study and migrate to Australia permanently (Onshore and Offshore). </p>
                                                <strong>TCM Global Migration and Education Solutions</strong> have helped thousands of aspirants in making their dream come true for their international Education and permanent Migration to the countries other than their home countries. */}
{/*TCM Global - Migration & Education Solutions is the best immigration consulting company who delivers seamless end-to-end guidance to aspiring clients in achieving their life goals without facing any hurdles.
                                                    By making good use of their affluent experience in the immigration process, our consultants cater a clear picture to the clients regarding the entire immigration process and guide them in clearing the related complexities in a hassle free way.

                                                    We follow a unique and standardised approach in providing immigration services like Permanent Residence, Temporary Residence Visa that help our clients to get landed in the perfect destination of opportunities according to their profile.
                                                */}   
                                                                                               
                                            </div>
                                        </div>
                                    </div>

                                  
                                   

                                </div>
                            </div>
                        </div>
                       <hr/>
						{ /* Counetr section 
							<SectionCounter />*/ }
						{ /* Counetr section End*/ }
							<VisionBlog />
							
                        {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bg2 + ")", backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 ">
                                    <TestimonialCarousel />
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END --> */}
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
							{/*<FormStyle /> */}
							 <hr/>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
function VisionBlog(){
	return(
		<>
			<div className="section-ful our-about-info content-inner-1 " style={{ backgroundImage: "url(" + bg2 + ")",backgroundPosition:"center" ,backgroundRepeat:"no-repeat" }}>
				<div className="container">
					<div className="section-head text-center">
						<h2 className="box-title m-tb0">Mission And Vision<span className="bg-primary"></span>
                        </h2>
					
					</div>
					<div className=" row dzseth  m-b30">
						<div className="col-lg-6 col-md-12 m-b30 about-img ">
							<img src={services1} data-tilt alt="" />
						</div>
						<div className="col-lg-6 col-md-12 m-b30 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Mission<span className="bg-primary"></span></h3>
								<p className="font-16">Our Mission is to make the overseas enrolment process easier and transparent for international students giving them decent options to study further that match their interests, educational background, and future goals.</p>
                                <p className="font-16">
                                We believe there is a pathway for everyone who wishes to migrate to Australia and an excellent migration specialist should stand by the client’s side, guiding and helping them through every step of the way. This is why TCM Global exists. The purpose of our existence is to serve our clients, making their education and migration journey hassle-free and providing them with all the assistance they need to settle in Australia.
</p>
								</div>
						</div>
					</div>
					<div className="row dzseth column-reverse">
						<div className="col-lg-6 col-md-12 dis-tbl text-justify">
							<div className="dis-tbl-cell">
								<h3 className="box-title">Our Core Values<span className="bg-primary"></span></h3>
								
                                <p className="font-16">
                                Our team is based on MARA Registered Agent, Qualified Education Agent Counsellors, and the support team to ensure the best approach to deal with all the cases keeping our core values in mind.
<ul><li>Focus</li>		<li>Honesty</li> 		<li>Quality</li>				<li>Reliability</li><li>Transparency</li> </ul>

                                </p>
							</div>
						</div>
						<div className="col-lg-6 col-md-12 about-img ">
							<img src={services2} data-tilt alt="" />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}	

export {VisionBlog};
export default Aboutus1;