import React, { Component } from 'react';
import Header from '../Layout/header3';
import Footer from '../Layout/footer4';
import PageTitle from '../Layout/PageTitle';
//import Sidebar from './../Element/SidebarServices'
//import {ListMainBlog} from './BlogList/BlogListLeftSidebar';
//Images
import bnr1 from './../../images/banner/bnr3.jpg';
import {Link} from "react-router-dom";

class OverseasOffice extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white" >
                   {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Overseas Office & Team" activeMenu="Overseas Office & Team" />
          </div>
          {/* <!-- inner page banner END --> */}
                    <div className="content-area">
                        <div className="container">
                            <div className="row">
                                {/* Main Blog List Sidebar */}
                                <div className="col-lg-8 fly-box-ho">
                                    <div className="blog-post blog-md clearfix" >
                                        <div className="dlab-post-info">
                                            <div className="dlab-post-title ">
                                                <h4 className="post-title"><Link to={"#"}>Team</Link></h4>
                                            </div>
                                            <div className="et_pb_text_inner">
                                            
                                                <p><h3>Sakhshi Chanana</h3>
                                                Sakhshi Chanana is the head of Operations for TCM Global and comes with an extensive experience of 10 years in the Education Industry combining Onshore and Offshore. She has worked and managed the international Admissions team in different Australian institutes and specializes in GTE assessment for both onshore and offshore students.  
                                </p><p >
                                <h3>Gaurav Chanana</h3>Gaurav Chanana is the Director of TCM Global and the Registered Migration Agent (MARN 2217868). He has more than 5 years of experience in the Migration and Education industry and has recruited many international students in different Australian institutes with great success in visa applications. 
He specializes in migration and provides guidance and advice regarding Australian immigration in accordance with Australian government regulations and assures a high rate of success. 

                               </p> <h4>Overseas  Team</h4>
                                <p>The Career Makers Overseas Education Consultants (License No. 475/MC-1/MA/F.No/254) is proud to be present in the Education Market as one of the most Trusted and Genuine companies at the National & International Levels promoting education in different countries since 2010 in India.  
</p><p>We have successfully recruited several students in Australia, New Zealand, Canada, and the United Kingdom and received a high rate of success in other visa applications like Business visas, Tourist visas, and partner/spouse visas. 
</p>
<p>We have three offices across the globe including India and Australia. Our phenomenal growth in the last decade stands as a testimony to our success in guiding positively through students' needs onshore and offshore. With several such exciting developments, we believe we have a lot to offer in international student recruitment. 
</p><p>We also provide different English language programs in our IELTS/PTE Coaching centre. Over 2 offices across PUNJAB, we provides all the education and migration services under one roof. 
</p>
<h3>Meet our team in India</h3>      
<ul>
  <li>Harish Chanana  </li>
  <li>Munish Chanana  </li>
  <li>IELTS Trainer </li>
</ul>                                
                                                 </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
export default OverseasOffice;