import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/header3";
import Footer from "./../Layout/footer4";
import PageTitle from "./../Layout/PageTitle";
import SectionCounter from "./../Element/SectionCounter";
//import TestimonialCarousel from "./../Element/TestimonialCarousel";
//import FormStyle from "./../Element/FormStyle";

//Images
import bnr1 from "./../../images/banner/bnr5.jpg";
import php from "./../../images/expertise/php.png";
import joomla from "./../../images/expertise/joomla.png";
import drupal from "./../../images/expertise/drupal.png";
import laravel from "./../../images/expertise/laravel.png";
import magento from "./../../images/expertise/magento.png";
import wordpress from "./../../images/expertise/wordpress.png";
import oscommerce from "./../../images/expertise/oscommerce.png";
import ecommerce from "./../../images/expertise/ecommerce.png";
import zend from "./../../images/expertise/zend-framework.png";
import ruby from "./../../images/expertise/ruby-rails.png";
import zencart from "./../../images/expertise/zencart.png";
import xcart from "./../../images/expertise/xcart.png";
import python from "./../../images/expertise/python-framework.png";
//import bg1 from "./../../images/background/bg1.jpg";
//import bgmap from "./../../images/background/bg-map.jpg";

/* const imageBlog = [
  { image: php, title: "Php" },
  { image: joomla, title: "Joomla" },
  { image: drupal, title: "Drupal" },
  { image: laravel, title: "Laravel" },
  { image: magento, title: "Magento 1.x" },
  { image: magento, title: "Magento 2.x" },
  { image: wordpress, title: "Wordpress" },
  { image: oscommerce, title: "Oscommerce" },
  { image: ecommerce, title: "ecommerce" },
  { image: zend, title: "Zend Framework" },
  { image: ruby, title: "Ruby Rails" },
  { image: zencart, title: "Zencart" },
  { image: xcart, title: "Xcart" },
  { image: python, title: "Python Framework" },
]; */

class BusinessImmigration extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Expertise" activeMenu="Expertise" />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="section-head text-black ">
                      <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                      <h2 className="box-title m-tb0">
Business in Australia                         
                      </h2> <p className="text-black text-justify">
                      Have you completed your studies in Australia but now you are at the stage of establishing your own business in a country like Australia. As we all are fully aware that purchasing a business in Australia or migrating to this outstanding country as a business owner or investor, it is critical to get the right immigration suggestion upfront. Having a sound immigration strategy at the same point can streamline matters significantly, and it also makes sure that you meet your goals in starting a business in a country like Australia.
</p><p className="text-black text-justify">
There are myriad of Australian work visas that business people can easily obtain for themselves or their employees that combine up as:
</p><p className="text-black text-justify">
The Government of Australia selects a particular type of business class immigrants based on their ability to become economically established and support the development of the economy of Australia. As we all are aware that, Business immigrants are people who can invest in, or start businesses in Australia and are expected to support the development of a strong and prosperous Australian economy. 
</p>                  

 
                        <h3>There are two types of classes of business immigrants present in Australia:

</h3>
                          <ul>
                        <li>
                        Those who enter with a start-up visa
                        </li>
                        <li>
                        Self-employed persons        
                        </li>
                        </ul>
                       
                        <h3 className="box-title m-tb0">How Australia is an ideal Country for Business & Investment?</h3>
                        <p className="text-black text-justify">
                            This beautiful country is marked as only economy to consistently rank in the world’s top five most resilient economies since 2008.This country has strong ties with Asia, Europe, and the Americas in terms of trade, business, and investment. You can also consider that it is the 12th largest economy in the world. Its surveyed institutions are among the best in the world and offer chances for private-sector industry collaborations. If you also want to start your business in Australia then kindly consult <b>Best Migration Consultant in Australia</b>. 
                        </p>
                    
                        <div class="p-a30 bg-gray">As more than 18,000 foreign companies are registered in this country, including 18 of the topmost 20 basic Fortune Global 500 and eight of the top 10 Fortune 100.
                        </div>


                        <h3 className="box-title m-tb0"> How you can become a Self-Employed Persons in Australia</h3>
To be eligible for this specific visa, you must have one of these:
<ul>
                         
                        <li>You can operate a new or existing business in Australia
                        </li>
                        <li>You can stay in Australia for up to 4 years and 3 months
                        </li>
                        <li>Bring eligible members of your family with you</li>
                        </ul>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default BusinessImmigration;
