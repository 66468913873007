import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
 
const EmailContactForm = () => {
 const form = useRef();
 
 const sendEmail = (e) => {
   e.preventDefault(); // prevents the page from reloading when you hit “Send”
 
   emailjs.sendForm('service_4jvunno', '__ejs-test-mail-service__', form.current, 'lEN69lQd6E1BacJQ2')
     .then((result) => {
        console.log('successfully sent');
     }, (error) => {
        console.log('Error sending email');
     });
 };
 
 return (
//    <form ref={form} method="post" onSubmit={sendEmail}>  
<form  method="post" action="contact.php">
                                                        <input type="hidden" value="Contact" name="dzToDo" />
                                                        <div className="row">
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Name</label>
                                                                        <input name="dzName" type="text" required className="form-control" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Phone</label>
                                                                        <input name="dzPhone" type="text" required className="form-control" placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Email Address</label>
                                                                        <input name="dzEmail" type="email" className="form-control" required placeholder="" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                                                <div className="form-group">
                                                                    <div className="input-group">
                                                                        <label>Your Message</label>
                                                                        <textarea name="dzMessage" rows="4" className="form-control" required placeholder=""></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12">
                                                                <button name="submit" type="submit" value="Submit" className="site-button white button-md m-t10">Submit Now</button>
                                                            </div>
                                                        </div>
                                                    </form>
     
   
 );
};
 
export default EmailContactForm;