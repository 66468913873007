import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/header3';
import Footer from './../Layout/footer4';
import PageTitle from './../Layout/PageTitle';
//import {VisionBlog} from './Aboutus/Aboutus1';

import bnr from './../../images/banner/bnr3.jpg';
import pic1 from './../../images/our-team/dummy-image.jpg';
import pic2 from './../../images/our-team/dummy-image.jpg';
import pic3 from './../../images/our-team/dummy-image.jpg';
import pic4 from './../../images/our-team/dummy.jpg';

const teamBlog=[
	{images: pic1,name:'Harish Chanana',title:'Founder and Managing Director (India)',}, {images: pic3,name:'Munish Chanana',title:'Director and Head of Operations (India)',},
	{images: pic2,name:'Gaurav Chanana',title:'Registered Migration Agent-MARN:2217868, Founder and Managing Director (Australia)',}, {images: pic4,name:'Sakhshi Chanana',title:'Director and Head of Operations (Australia)',},
]

class Team extends Component {
	componentDidMount() {
    }
    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white">
                    {/* <!-- inner page banner --> */}
                    <div className="dlab-bnr-inr overlay-primary" style={{ backgroundImage: "url(" + bnr + ")" }}>
                        <PageTitle motherMenu='Our Team' activeMenu='Our Team'  />
                    </div>
                    {/* <!-- inner page banner END --> */}
                    {/* <!-- contact area --> */}
                    <div className="content-block">
                        {/* <!-- Team Section --> */}
                        <div className="section-full text-center bg-white content-inner-1">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2>Team @ TCM Global - Migration & Education Solutions</h2>
                                     
                                </div>
                                <div className="row ">
									{teamBlog.map((data,index)=>(
										<div className="col-lg-3 col-md-6 col-sm-6 m-b5" key={index}>
											<div className="dlab-box">
												<div className="dlab-media dlab-img-overlay6 dlab-img-effect radius-sm"> 
													<img src={data.images} alt="" />
													<div className="overlay-bx">
														<div className="overlay-icon">
															<ul className="dlab-social-icon">
																<li><Link to={"#"} className="fa text-white fa-facebook"></Link></li>
																<li><Link to={"#"} className="fa text-white fa-twitter"></Link></li>
																<li><Link to={"#"} className="fa text-white fa-linkedin"></Link></li>
																<li><Link to={"#"} className="fa text-white fa-facebook"></Link></li>
															</ul>
														</div>
													</div>
												</div>
												<div className="dlab-title-bx p-a10">
													<h5 className="text-black m-a0">{data.name}</h5>
													<span className="clearfix">{data.title}</span>
												</div>
											</div>
										</div>
									))}
                                </div>
                            </div>
                        </div>
                        {/* <!-- Team Section END --> */}
                        {/* <!-- Mission And Vision -->
							<VisionBlog />
                        <!-- Mission And Vision END --> */}
                    </div>
                    {/* <!-- contact area END --> */}
                </div>
<hr/>
                <Footer />
            </>
        )
    }
}
export default Team;