import React,{Fragment, Component} from 'react';
import {Link} from 'react-router-dom';
import Counter from './Counter';

//import bg1 from './../../images/background/bg1.jpg';
import bg1 from './../../images/background/tcm-global-contact-background.jpg';

const iconBlog =[
	{icon: <i className="flaticon-pen" />,	   title: '100% Accurate',desc:'The filing is guaranteed to be 100% accurate.',},
	{icon: <i className="flaticon-diamond" />, title: '100% Success Rate',desc:'For the past 15 years, we\'ve been assisting customers.',},
	{icon: <i className="flaticon-devices" />, title: 'No Hidden Charges',desc:'There are no hidden charges with a flat fee.',},
];

class SectionCounter extends Component{
	render(){
		return(
			<Fragment>
				<div className="section-full content-inner-1 overlay-primary about-service bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
					<div className="container">
						<div className="section-head text-white text-center">
							<h3 className="box-title m-tb0 max-w800 m-auto">Our services are priceless and something that you definitely need to build up your future in Canada, for a better living standards. With us you will be prepared for your dreams!<span className="bg-primary"></span></h3>
							<p></p>
						</div>
					</div>
					<div className="container">
						<div className="row text-white">
							{iconBlog.map((item, index)=> (
								<div className="col-lg-4 col-md-4 col-sm-6 m-b30" key={index}>
									<div className="icon-bx-wraper bx-style-1 p-a30 center">
										<div className="icon-lg text-white m-b20"> <Link to={"#"} className="icon-cell text-white">{item.icon}</Link> </div>
										<div className="icon-content">
											<h5 className="dlab-tilte text-uppercase">{item.title}</h5>
											<p>{item.desc}</p>
										</div>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="choses-info text-white">
						<Counter />
					</div>
				</div>	
			</Fragment>
		)
	}
}
export default SectionCounter;