import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/header3";
import Footer from "./../Layout/footer4";
import PageTitle from "./../Layout/PageTitle";
import SectionCounter from "./../Element/SectionCounter";
//import TestimonialCarousel from "./../Element/TestimonialCarousel";
//import FormStyle from "./../Element/FormStyle";

//Images
import bnr1 from "./../../images/banner/bnr5.jpg";
import php from "./../../images/expertise/php.png";
import joomla from "./../../images/expertise/joomla.png";
import drupal from "./../../images/expertise/drupal.png";
import laravel from "./../../images/expertise/laravel.png";
import magento from "./../../images/expertise/magento.png";
import wordpress from "./../../images/expertise/wordpress.png";
import oscommerce from "./../../images/expertise/oscommerce.png";
import ecommerce from "./../../images/expertise/ecommerce.png";
import zend from "./../../images/expertise/zend-framework.png";
import ruby from "./../../images/expertise/ruby-rails.png";
import zencart from "./../../images/expertise/zencart.png";
import xcart from "./../../images/expertise/xcart.png";
import python from "./../../images/expertise/python-framework.png";
//import bg1 from "./../../images/background/bg1.jpg";
//import bgmap from "./../../images/background/bg-map.jpg";

/* const imageBlog = [
  { image: php, title: "Php" },
  { image: joomla, title: "Joomla" },
  { image: drupal, title: "Drupal" },
  { image: laravel, title: "Laravel" },
  { image: magento, title: "Magento 1.x" },
  { image: magento, title: "Magento 2.x" },
  { image: wordpress, title: "Wordpress" },
  { image: oscommerce, title: "Oscommerce" },
  { image: ecommerce, title: "ecommerce" },
  { image: zend, title: "Zend Framework" },
  { image: ruby, title: "Ruby Rails" },
  { image: zencart, title: "Zencart" },
  { image: xcart, title: "Xcart" },
  { image: python, title: "Python Framework" },
]; */

class CourseChange extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Expertise" activeMenu="Expertise" />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="section-head text-black ">
                      <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                      <h2 className="box-title m-tb0">
                      Course Change in Australia
                      </h2> <p className="text-black text-justify">

                      
                      
"Can I change my course in Australia?" well the answer is certainly yes, but let us tell you, you are not the only one. There comes a time in our lives where we all become skeptical about one thing or the other. Having second thoughts about your studies or being unhappy at your university which you chose with so many expectations is pretty normal. You might be considering changing or leaving your course. This guide will help you, understand the options that are available for you. TCM Global offers the much-needed assistance as to how to convince the authorities about your true intention to change course in Australia
</p>
<h2>Why are you considering changing or leaving your course?</h2>
<p className="text-black text-justify">Before deciding upon anything, you should always try to identify the problem and try to solve it. Following are some issues faced by the students:
</p>
<ul>
    <li>Individual modules</li>
    <li>The course</li>
    <li>The university</li>
    <li>The academic challenges of studying at the university level</li>
    <li>Personal/family reasons</li>
    <li>Finances</li>
</ul>
<p className="text-black text-justify">Your next step should be to take advice and clarify the different paths open to you. Following this, you can weigh up the pros and cons and choose the course of action that you think will suit you best.
</p>

<h2>Who can help you change or leave your course in Australia?</h2>
<p className="text-black text-justify">Usually, the authorities around you can help you with any doubt you have. You can consider meeting the following people/bodies:
</p>
Your course tutor or department office should be your first port of call and they would be advising you on the following:
    <ul>
        <li>how you could adapt or tailor your course to suit your interests, strengths, or career plans</li>
        <li>how to access help with study skills</li>
        <li>how to transfer to another course</li>
        <li>Your careers service can give you advice on your career options and the potential impact of changing or leaving your course.</li>
        <li>Speak to student services at your university to find out how your entitlement to funding will be affected by your plans.</li>
</ul>
<h2>How to change course in Australia?</h2>
    
<p className="text-black text-justify">We wonder that can international students change courses in Australia. The process of the course change/transfer is a comprehensive one, have a look below:
</p>
<ul>
    <li>In case you make up your mind to change of course in Australia but you have not completed six months of your principal course, then under ESOS National Code, such circumstances are explained. Unless special conditions apply, you need to have permission from your existing institution to transfer to another institution. The institution must assess or consider your request to transfer. All the educational institutions in Australia have documented procedures for their transfer policy. Make sure you understand your education provider’s transfer policy and what your written agreement says before you attempt to enroll with a new a new institution.
</li>
<li>In case, your institution does not give you permission to transfer to another course/institution in Australia and you are not satisfied with the outcome, you should first use your education provider’s internal appeal process. If you are not satisfied still, you can then appeal against the institution’s decision at external complaints handling body such as the State or Territory Ombudsman or the Overseas Student Ombudsman. Remember, if you change your institution or course at any time you must contact the Department of Home Affairs, Australia, and provide an electronic Confirmation of Enrolment (eCoE) certificate from your new institution.
</li>
<li>Once your transfer application is granted, you must check your student visa requirements and ensure you follow the correct procedures to maintain your visa. It is very vital that you do not assume that the advice you get from friends, fellow students, or education agents is correct. Make sure to keep a copy of any course cancellation and enrolment paperwork.
</li>
</ul>

<p className="text-black text-justify">You also need to consider visiting the Department of Home Affairs, Australia as there will be changes in your visa. In order to remain updated and abiding by the law, do visit the department or contact immigration consultancies like TCM Global</p>

<h2>How can we help -</h2>
<ul>
<li>TCM Global offer the much-needed assistance as to how to convince them about your true intention to study in Australia.
</li>
<li>We do everything to present you as a genuine applicant so that you can be trusted that you will adhere to all the rules while in Australia.
</li>
<li>We also make sure that your interview round goes well which is yet another successful step to get a visa for the same.               
</li>
</ul>                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default CourseChange;
