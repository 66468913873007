import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "./../Layout/header3";
import Footer from "./../Layout/footer4";
import PageTitle from "./../Layout/PageTitle";
import SectionCounter from "./../Element/SectionCounter";
//import TestimonialCarousel from "./../Element/TestimonialCarousel";
//import FormStyle from "./../Element/FormStyle";

//Images
import bnr1 from "./../../images/banner/bnr5.jpg";
import php from "./../../images/expertise/php.png";
import joomla from "./../../images/expertise/joomla.png";
import drupal from "./../../images/expertise/drupal.png";
import laravel from "./../../images/expertise/laravel.png";
import magento from "./../../images/expertise/magento.png";
import wordpress from "./../../images/expertise/wordpress.png";
import oscommerce from "./../../images/expertise/oscommerce.png";
import ecommerce from "./../../images/expertise/ecommerce.png";
import zend from "./../../images/expertise/zend-framework.png";
import ruby from "./../../images/expertise/ruby-rails.png";
import zencart from "./../../images/expertise/zencart.png";
import xcart from "./../../images/expertise/xcart.png";
import python from "./../../images/expertise/python-framework.png";
/* import bg1 from "./../../images/background/bg1.jpg";
import bgmap from "./../../images/background/bg-map.jpg";

const imageBlog = [
  { image: php, title: "Php" },
  { image: joomla, title: "Joomla" },
  { image: drupal, title: "Drupal" },
  { image: laravel, title: "Laravel" },
  { image: magento, title: "Magento 1.x" },
  { image: magento, title: "Magento 2.x" },
  { image: wordpress, title: "Wordpress" },
  { image: oscommerce, title: "Oscommerce" },
  { image: ecommerce, title: "ecommerce" },
  { image: zend, title: "Zend Framework" },
  { image: ruby, title: "Ruby Rails" },
  { image: zencart, title: "Zencart" },
  { image: xcart, title: "Xcart" },
  { image: python, title: "Python Framework" },
]; */

class VisitorToStudentVisa extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content bg-white">
          {/* <!-- inner page banner --> */}
          <div
            className="dlab-bnr-inr overlay-primary"
            style={{ backgroundImage: "url(" + bnr1 + ")" }}
          >
            <PageTitle motherMenu="Expertise" activeMenu="Expertise" />
          </div>
          {/* <!-- inner page banner END --> */}
          <div className="content-block">
            {/* <!-- About Us --> */}
            <div className="section-full content-inner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 col-md-7">
                    <div className="section-head text-black ">
                      <h4 className="text-gray-dark font-weight-300 m-b10"></h4>
                      <h2 className="box-title m-tb0">
                      Visitor to Student Visa
                      </h2> <p className="text-black text-justify">

                      
Australia is a wonderful place to study for many of the international students. It offers a good quality of life and plenty of well-respected educational institutions to choose, you can study, work and live in this country. If you have picked a course that will be less than three months in duration, you can complete it while in Australia on a Visitor visa. Applying for a student visa in Australia can be a hectic process, but it doesn’t have to be. If you’re already in Australia on a Visitor visa, you can easily apply online without leaving Australia. There are some other fixed rules set for a visa in Australia and the restrictions of each visa type are also mentioned, so you need to carefully follow the steps below to avoid any further issues. Your Student visa to Australia can only be granted after you have received a Confirmation of Enrolment letter from your chosen institution. This means that you need to apply directly to an accredited institution and be accepted into a course before applying for your visa. Initially, if you are accepted to study at a university and college, you will receive an Offer Letter as well as an acceptance form. Once you accept the offer from the university and provide all required documents and fees, the institution will then send you an electronic Confirmation of Enrolment (CoE). For Australia student visa, you must be enrolled in a full-time CRICOS registered course of study, satisfy the Genuine Temporary Entrant requirements, provide evidence of your English skill level, hold adequate Overseas Student Health Cover (OSHC) insurance, and meet health and character standards.
</p>                  
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-5 sticky-top">
                    <form
                      className="inquiry-form inner dzForm"
                      action="script/contact.php"
                    >
                      <div className="dzFormMsg"></div>
                      <h3 className="box-title m-t0 m-b10">
                        Need Help? <span className="bg-primary"></span>
                      </h3>
                      <p></p>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-user text-primary"></i>
                              </span>
                              <input
                                name="dzName"
                                type="text"
                                required
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-mobile text-primary"></i>
                              </span>
                              <input
                                name="dzOther[Phone]"
                                type="text"
                                required
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-email text-primary"></i>
                              </span>
                              <input
                                name="dzEmail"
                                type="email"
                                className="form-control"
                                required
                                placeholder="Your Email Id"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <span className="input-group-addon">
                                <i className="ti-agenda text-primary"></i>
                              </span>
                              <textarea
                                name="dzMessage"
                                rows="4"
                                className="form-control"
                                required
                                placeholder="Type your message here...."
                              ></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <div className="input-group">
                              <div
                                className="g-recaptcha"
                                data-sitekey="6LefsVUUAAAAADBPsLZzsNnETChealv6PYGzv3ZN"
                                data-callback="verifyRecaptchaCallback"
                                data-expired-callback="expiredRecaptchaCallback"
                              ></div>
                              <input
                                className="form-control d-none"
                                data-recaptcha="true"
                                required
                                data-error="Please complete the Captcha"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            name="submit"
                            type="submit"
                            value="Submit"
                            className="site-button button-lg"
                          >
                            {" "}
                            <span>Send Message!</span>{" "}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <SectionCounter />
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Testimonials -->
                        <div className="section-full content-inner" style={{ backgroundImage: "url(" + bgmap + ")",  backgroundPosition: "center", backgroundRepeat: "no-repeat" }}>
                            <div className="container">
                                <div className="section-head text-center">
                                    <h2 className="box-title m-tb0">Our Testimonials<span className="bg-primary"></span></h2>
                                    <p> Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the </p>
                                </div>
                                <div className="section-content m-b30 	">
								 <TestimonialCarousel /> 
                                </div>
                            </div>
                        </div>
                         <!-- Testimonials END -- >
                         <!-- Get in touch -- >
                        <div className="section-full overlay-primary-dark bg-img-fix" style={{ backgroundImage: "url(" + bg1 + ")" }}>
                            <FormStyle />
                        </div>
                         <!-- Get in touch --> */}
          </div>
          {/* <!-- contact area END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default VisitorToStudentVisa;
